import classNames from 'classnames'
import Link from 'next/link'
import { CSSProperties, FC, ReactNode, useRef } from 'react'

import useChangeFontSize from '@promova/utils/customHooks/useChangeFontSize'

import styles from './fit_size_text.module.scss'

interface Props {
  text: string | ReactNode
  className?: string
  wrapperClassName?: string
  Tag?: 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  isNoWrap?: boolean
  href?: string
  style?: CSSProperties
}

const FitSizeText: FC<Props> = ({
  text,
  className,
  wrapperClassName,
  Tag = 'p',
  isNoWrap,
  href,
  style,
}) => {
  const parentRef = useRef<HTMLParagraphElement>(null)
  const textRef = useRef<HTMLSpanElement>(null)

  const fontSize = useChangeFontSize(parentRef, textRef)

  const wrapperClassNames = classNames(styles.wrap, wrapperClassName)
  const textClassNames = classNames(className, {
    [styles.no_wrap]: isNoWrap,
  })

  return (
    <Tag ref={parentRef} className={wrapperClassNames} style={style}>
      <span className={textClassNames} ref={textRef} style={{ fontSize }}>
        {href ? <Link href={href}>{text}</Link> : text}
      </span>
    </Tag>
  )
}

export default FitSizeText
