import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { t } from '@lingui/macro'
import { useRouter } from 'next/router'
import { FC, MouseEvent, useContext, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import LogoIcon from '@promova/ui/icons/diya.svg'

import { useAppSelector } from '@reduxStore/hooks'

import { SHOW_FREE_FOR_UKRAINE_BUTTON } from '@constants_folder/remote_config'
import { FREE_FOR_UKRAINE_ROUTE } from '@constants_folder/routes'

import { AuthContext } from '@modules/Auth/AuthProvider'

import FitSizeText from '@elements/FitSizeText/FitSizeText'
import FreeUkraineShortInfo from '@elements/FreeUkraineShortInfo/FreeUkraineShortInfo'

import styles from './ukraine_btn.module.scss'

interface Props {
  onClick?: (...args: any[]) => void
  className?: string
  useDropdown?: boolean
}

const hideUkButtonRoutes = ['/free-for-ukraine']

const FreeUkraineButton: FC<Props> = (props) => {
  const { onClick, className = '', useDropdown = true } = props
  const isDesktop = useMediaQuery({
    query: '(min-width: 1100px)',
  })
  const router = useRouter()
  const [open, setOpen] = useState(false)
  const { user, loading } = useContext(AuthContext)

  const hasCoursesPremium = useAppSelector(
    (state) => state.profile.hasCoursesPremium
  )
  const isSubscriptionFetched = useAppSelector(
    (state) => state.profile.isSubscriptionFetched
  )

  const shouldShowBtn = useFeatureIsOn(SHOW_FREE_FOR_UKRAINE_BUTTON)

  const handleClose = () => {
    setOpen(false)
  }

  const toggleOpen = () => {
    setOpen((prevOpen) => {
      if (!prevOpen) {
        window.addEventListener('click', handleClose)
      } else {
        window.removeEventListener('click', handleClose)
      }
      return !prevOpen
    })
  }

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e?.stopPropagation()

    if (useDropdown && isDesktop) {
      toggleOpen()
    } else if (useDropdown && !isDesktop) {
      router.push(FREE_FOR_UKRAINE_ROUTE)
    } else if (typeof onClick === 'function') {
      onClick()
    }
  }

  const hideButton = hideUkButtonRoutes.includes(router?.pathname)

  if (
    !shouldShowBtn ||
    loading ||
    (user && !isSubscriptionFetched) ||
    hasCoursesPremium ||
    hideButton
  ) {
    return null
  }

  return (
    <div className={styles.dropdown_container}>
      <button
        type="button"
        onClick={handleClick}
        className={`${styles.ukraine_btn} ${className}`}
      >
        <LogoIcon className={styles.btn_log} />
        <FitSizeText text={t`Free for Ukrainians`} Tag="span" />
      </button>
      {useDropdown && isDesktop && open && (
        // eslint-disable-next-line
        <div className={styles.dropdown} onClick={(e) => e.stopPropagation()}>
          <FreeUkraineShortInfo />
        </div>
      )}
    </div>
  )
}

export default FreeUkraineButton
