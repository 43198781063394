import sourcebuster from 'sourcebuster'

import { UTMS_FROM_SOURCEBUSTER } from '@constants'

import { getUtmsFromSessionStorage } from '@hooks/saveEntryPoint'

export const createLinkWithUtms = (link: string) => {
  if (typeof window === 'undefined' || !link) return link

  const utms: Record<string, string> = getUtmsFromSessionStorage()

  const isSourcebusterInited = !!sourcebuster?.get
  if (isSourcebusterInited) {
    Object.entries(UTMS_FROM_SOURCEBUSTER).forEach(([key, value]) => {
      const paramValue = sourcebuster?.get.current[key]
      if (paramValue && paramValue !== '(none)' && !utms[`${value}`]) {
        utms[`${value}`] = paramValue
      }
    })
  }

  let linkWithUtms = link
  const hasQuery = linkWithUtms.includes('?')

  Object.entries(utms).forEach(([key, value], i: number) => {
    if (i === 0 && value && !hasQuery) {
      linkWithUtms += `?${key}=${value}`
    } else if (value) {
      linkWithUtms += `&${key}=${value}`
    }
  })

  return linkWithUtms
}
