import { useRouter } from 'next/router'

const useLandingInfo = () => {
  const { asPath } = useRouter()

  const LANDING_PATH = asPath.split('?')[0]
  const LANDING_NAME = LANDING_PATH?.slice(
    LANDING_PATH.lastIndexOf('/') + 1
  ).replace(/-/g, ' ')

  return { LANDING_NAME, LANDING_PATH }
}

export default useLandingInfo
