import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import { signOut } from 'firebase/auth'
import Link from 'next/link'
import { FC, useContext, useRef } from 'react'

import { NavigationScheme } from '@constants_folder/navigationScheme'

import { useClickOutside } from '@hooks/customHooks'

import { AuthContext } from '@modules/Auth/AuthProvider'

import styles from './dropdown_navigation.module.scss'

interface Props {
  active: boolean
  onItemClick: (e: React.SyntheticEvent<HTMLElement>) => void
  links: NavigationScheme['items']
}

const DropdownNavigation: FC<Props> = ({ active, onItemClick, links }) => {
  const popupRef = useRef<HTMLUListElement>(null)

  const { user, auth } = useContext(AuthContext)

  useClickOutside(active, popupRef, onItemClick)

  const handleSignOut = (e: React.SyntheticEvent<HTMLElement>) => {
    if (auth) {
      signOut(auth)
    }

    onItemClick(e)
  }

  const profileMenuClassNames = classNames(styles.profile_menu, {
    [styles.profile_menu_active]: active,
  })

  return (
    <ul className={profileMenuClassNames} ref={popupRef}>
      {links?.map((item) => (
        <li key={item.route}>
          <Link href={item.route}>
            {/* eslint-disable-next-line */}
            <a
              onClick={onItemClick}
              className={styles.nav_item}
              data-event={item.analytics?.eventName}
            >
              {item.title}

              {item?.haveFreeBadge && (
                <span className={styles.free_badge}>
                  <Trans>Free</Trans>
                </span>
              )}
            </a>
          </Link>
        </li>
      ))}
      {user && (
        <li>
          <button
            type="button"
            onClick={handleSignOut}
            className={styles.nav_item_red}
            data-event="gen_clicked_log_out"
          >
            <Trans>Sign out</Trans>
          </button>
        </li>
      )}
    </ul>
  )
}

export default DropdownNavigation
