import { useMediaQuery } from 'react-responsive'
import { MediaQueryAllQueryable } from 'react-responsive/src/types'

const useBreakpoints = (
  mediaQueryProps: MediaQueryAllQueryable = { minWidth: 1 }
) => ({
  xs: useMediaQuery({ maxWidth: 767 }),
  sm: useMediaQuery({ minWidth: 768 }),
  md: useMediaQuery({ minWidth: 1025 }),
  mdDown: useMediaQuery({ maxWidth: 1024 }),
  tablet: useMediaQuery({ maxWidth: 1023 }),
  lg: useMediaQuery({ minWidth: 1200 }),
  lgBig: useMediaQuery({ minWidth: 1440 }),
  lgBigDown: useMediaQuery({ maxWidth: 1439 }),
  lgDown: useMediaQuery({ maxWidth: 1199 }),
  xlg: useMediaQuery({ minWidth: 1920 }),
  xlgDown: useMediaQuery({ maxWidth: 1919 }),
  iphone5: useMediaQuery({
    minWidth: 320,
    maxWidth: 375,
    orientation: 'portrait',
  }),
  custom: useMediaQuery(mediaQueryProps),
})

export default useBreakpoints
