import { Trans } from '@lingui/macro'

import { RetenoEvent } from '@api/events'

import { Event } from '@constants_folder/analyticsEvents'
import {
  FAQ_ROUTE,
  LandingsRoutes,
  PagesRoutes,
  ProfileRoutes,
} from '@constants_folder/routes'

import { Config } from '@utils/analytics'

export type NavigationScheme = {
  title: JSX.Element | string
  route: string
  icon?: JSX.Element
  level?: number
  items?: NavigationScheme[]
  haveNext?: boolean
  haveFreeBadge?: boolean
  analytics?: Config
  retenoAnalytics?: Omit<RetenoEvent, 'token'>
}

export const PLATFORM_DROPDOWN_NAVIGATION: NavigationScheme['items'] = [
  {
    title: <Trans>Profile</Trans>,
    route: ProfileRoutes.profile,
    analytics: {
      eventName: 'gen_clicked_profile',
    },
  },
  {
    title: <Trans>Settings</Trans>,
    route: ProfileRoutes.settings,
    analytics: {
      eventName: 'gen_clicked_settings',
    },
  },
  {
    title: <Trans>FAQ</Trans>,
    route: FAQ_ROUTE,
    analytics: {
      eventName: 'gen_clicked_faq',
    },
  },
  {
    title: <Trans>Conversation club</Trans>,
    route: LandingsRoutes.conversationClub,
    analytics: {
      eventName: 'gen_clicked_conversation_club',
    },
    haveFreeBadge: true,
  },
  {
    title: <Trans>Contact us</Trans>,
    route: PagesRoutes.contactUs,
    analytics: {
      eventName: 'gen_clicked_contact_us',
    },
  },
]

export const LANDINGS_DROPDOWN_NAVIGATION: NavigationScheme['items'] = [
  {
    title: <Trans>Back to Studying</Trans>,
    route: '/',
  },
]

export const PROFILE_ROUTES = [
  {
    title: <Trans>My words</Trans>,
    route: ProfileRoutes.myWords,
    analytics: {
      eventName: 'revision_clicked_my_words',
    },
  },
]

export const SETTINGS_ROUTES = [
  {
    title: <Trans>Tutoring credits</Trans>,
    route: ProfileRoutes.creditBalance,
    analytics: {
      eventName: 'settings_clicked_tutoring_credits',
    },
  },
  {
    title: <Trans>Payments & subscriptions</Trans>,
    route: ProfileRoutes.paymentsAndSubscriptions,
    analytics: {
      eventName: 'settings_clicked_payments_and_subs',
    },
  },
  {
    title: <Trans>Password</Trans>,
    route: ProfileRoutes.password,
    analytics: {
      eventName: 'settings_clicked_change_personal_data',
      data: { data_type: 'password' },
    },
  },
  {
    title: <Trans>Email</Trans>,
    route: ProfileRoutes.email,
    analytics: {
      eventName: 'settings_clicked_change_personal_data',
      data: { data_type: 'email' },
    },
  },
  {
    title: <Trans>Phone number</Trans>,
    route: ProfileRoutes.phone,
    analytics: {
      eventName: 'settings_clicked_change_personal_data',
      data: { data_type: 'phone-number' },
    },
  },
  {
    title: <Trans>Linked accounts</Trans>,
    route: ProfileRoutes.linkedAccounts,
    analytics: {
      eventName: Event.SETTINGS_CLICKED_LINKED_ACCOUNTS,
    },
  },
  {
    title: <Trans>Manage my personal info</Trans>,
    route: ProfileRoutes.personalInfo,
    analytics: {
      eventName: 'settings_clicked_change_personal_data',
      data: { data_type: 'manage-info' },
    },
  },
  {
    title: <Trans>Website language</Trans>,
    route: ProfileRoutes.websiteLanguage,
    analytics: {
      eventName: 'settings_clicked_website_lang',
    },
  },
  {
    title: <Trans>Terms and policies</Trans>,
    route: ProfileRoutes.terms,
    analytics: {
      eventName: 'settings_clicked_terms_and_pol',
    },
  },
  {
    title: <Trans>Contact us</Trans>,
    route: ProfileRoutes.contactUs,
    analytics: {
      eventName: 'gen_clicked_contact_us',
      data: { place: 'settings' },
    },
  },
]

export const SETTINGS_ROUTES_WITHOUT_CREDITS = SETTINGS_ROUTES.filter(
  (route) => route.route !== ProfileRoutes.creditBalance
)

export const PROFILE_NAVIGATION: NavigationScheme['items'] = [
  {
    title: <Trans>Profile</Trans>,
    route: ProfileRoutes.profile,
    items: PROFILE_ROUTES,
  },
  {
    title: <Trans>Settings</Trans>,
    route: ProfileRoutes.settings,
    items: SETTINGS_ROUTES,
  },
]

export const PROFILE_NAVIGATION_WITHOUT_CREDITS: NavigationScheme['items'] = [
  {
    title: <Trans>Profile</Trans>,
    route: ProfileRoutes.profile,
    items: PROFILE_ROUTES,
  },
  {
    title: <Trans>Settings</Trans>,
    route: ProfileRoutes.settings,
    items: SETTINGS_ROUTES_WITHOUT_CREDITS,
  },
]

export const LESSONS_NAVIGATION: NavigationScheme['items'] = [
  {
    title: <Trans>Group lessons</Trans>,
    route: PagesRoutes.groupLessons,
    analytics: {
      eventName: 'gen_clicked_group_lessons',
      data: {
        place: 'Lessons',
      },
    },
    retenoAnalytics: {
      event_name: 'gen_clicked_group_lessons',
      params: {
        place: 'Lessons',
      },
    },
    items: [
      {
        title: <Trans>Upcoming</Trans>,
        route: PagesRoutes.groupLessons,
      },
      {
        title: <Trans>Past</Trans>,
        route: PagesRoutes.groupLessonsPast,
      },
    ],
  },
  {
    title: <Trans>1x1 lessons</Trans>,
    route: PagesRoutes.myTutor,
    analytics: {
      eventName: 'gen_clicked_1x1_lessons',
      data: {
        place: 'Lessons',
      },
    },
    items: [
      {
        title: <Trans>Upcoming</Trans>,
        route: PagesRoutes.myTutor,
      },
      {
        title: <Trans>Past</Trans>,
        route: PagesRoutes.myTutorPastLessons,
      },
    ],
  },
]
