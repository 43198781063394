import { RefObject, useEffect, useState } from 'react'

// this hook will decrease fontSize until the child is the same size as the parent.
// It is better to use the FitSizeText component since there is a dependency in styles
const useChangeFontSize = (
  parentRef: RefObject<HTMLElement>,
  childrenRef: RefObject<HTMLElement>
): number | undefined => {
  const [fontSize, setFontSize] = useState(0)

  useEffect(() => {
    const childrenElement = childrenRef?.current
    const parentElement = parentRef?.current

    if (!childrenElement || !parentElement) return

    const childrenFontSize = window.getComputedStyle(childrenElement)?.fontSize

    const fontSizeToNumber = (): number => {
      const separators = ['px', 'em', 'rm', '%']
      const unit = separators.find((possibleUnit) =>
        childrenFontSize.includes(possibleUnit)
      )

      const [size] = childrenFontSize.split(unit || '')

      return Number(size)
    }

    setTimeout(() => {
      if (parentElement?.offsetWidth < childrenElement?.offsetWidth) {
        setFontSize(fontSizeToNumber() - 1)
      }
    }, 1)
  }, [parentRef, childrenRef, fontSize])

  if (fontSize) {
    return fontSize
  }

  return undefined
}

export default useChangeFontSize
