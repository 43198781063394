import classNames from 'classnames'
import Link from 'next/link'
import { FC, MouseEvent } from 'react'

import Icon, { IconName } from '@promova/ui/components/Icon/Icon'

import { EMPTY_LINK, LinkTarget } from '@constants_folder/common'

import { createLinkWithUtms } from '@utils/createLinkWithUtms'

import styles from './button.module.scss'

export type ButtonSize = 's' | 'm' | 'l' // button size. 's' - 40px, 'm' - 50px, 'l' - 60px
type ButtonType = 'filled' | 'outlined' | 'filled_inverted'

export type ButtonV2Props = {
  link?: string
  type?: ButtonType
  size?: ButtonSize
  locale?: string
  className?: string
  onClick?: (e: MouseEvent<HTMLElement>) => void | any
  withScaling?: boolean
  isExternal?: boolean
  isDisabled?: boolean
  pulsed?: boolean
  replace?: boolean
  iconName?: IconName
  iconSize?: number
  [key: string]: any
}

const Button: FC<ButtonV2Props> = ({
  link,
  type = 'filled',
  size = 'l',
  className = '',
  onClick,
  children,
  withScaling = true,
  isExternal,
  isDisabled,
  pulsed,
  locale,
  replace,
  iconName,
  iconSize,
  ...rest
}) => {
  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (!link) e.preventDefault() // preventing scroll to top
    onClick?.(e)
  }
  const buttonClassNames = classNames(
    styles[`btn_${type}`],
    styles[`size_${size}`],
    {
      [styles.scaled_button]: withScaling,
      [styles.btn_disabled]: isDisabled,
      [styles.pulsed]: pulsed,
    },
    className
  )

  // TODO: remove createLinkWithUtms logic from this component
  const isOnelink = !!link?.includes('onelink')
  const hasUtm = !!link?.includes('utm_')
  const shouldAddUtm = isOnelink && !hasUtm

  const linkWithUtm = shouldAddUtm ? createLinkWithUtms(link as string) : link

  let anchorProps: Record<string, any>
  let buttonEventType: string | undefined
  if (Object.hasOwn(rest, 'eventType')) {
    const { eventType, ...otherAnchorProps } = rest
    anchorProps = otherAnchorProps
    buttonEventType = eventType
  } else {
    anchorProps = rest
  }

  return (
    // if receive locale=null will produce bug with routing
    <Link
      replace={replace}
      href={linkWithUtm || EMPTY_LINK}
      locale={locale || undefined}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a
        className={buttonClassNames}
        target={isExternal ? LinkTarget.BLANK : LinkTarget.SELF}
        rel={isExternal ? 'nofollow' : 'follow'}
        onClick={handleClick}
        data-redirect-to={link}
        data-event-type={buttonEventType}
        {...anchorProps}
      >
        {children}

        {iconName && (
          <Icon icon={iconName} className={styles.icon} size={iconSize} />
        )}
      </a>
    </Link>
  )
}

export default Button
