import { Trans } from '@lingui/macro'
import Link from 'next/link'
import { FC } from 'react'

import { FREE_FOR_UKRAINE_ROUTE } from '@constants'

import styles from './free_ukraine_info.module.scss'

const FreeUkraineShortInfo: FC = () => (
  <div className={styles.plate}>
    <Trans>
      <strong>Free English learning</strong> for 3 years from Promova.
    </Trans>
    <div>
      <Link href={FREE_FOR_UKRAINE_ROUTE} passHref>
        <a className={styles.black_btn}>
          <Trans>Learn more</Trans>
        </a>
      </Link>
    </div>
  </div>
)

export default FreeUkraineShortInfo
