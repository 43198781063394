import React, { FC } from 'react'

import Logo from '@promova/ui/icons/promova_logo_bold.svg'

import { useAppSelector } from '@reduxStore/hooks'

import { Themes } from '@_types/index'

import styles from './header_logo.module.scss'

const logoVariants: Record<Themes, FC> = new Proxy(
  {
    [Themes.DEFAULT]: () => <Logo className={styles.logo} />,
  },
  {
    get(target, prop: Themes): FC {
      return prop in target ? target[prop] : target[Themes.DEFAULT]
    },
  }
)

const HeaderLogo: FC = () => {
  const theme = useAppSelector((state) => state.theme.specialUITheme)

  const LogoComponent = logoVariants[theme]

  return <LogoComponent />
}

export default HeaderLogo
